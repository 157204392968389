<template>
  <v-container class="px-6 d-block" fluid>
    <div class="d-flex align-center mb-6">
      <v-icon size="15" color="#4ab762">mdi-home</v-icon>
      <v-breadcrumbs :items="breadcrumbs" class="pa-0 ml-2">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <v-row>
      <v-col cols="12" md="6">
        <v-card
          class="rounded-lg"
          style="box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px"
        >
          <v-card-title style="color: #4ab762" class="subtitle-1 py-2 d-block">
            Ubah Profil
            <div class="caption grey--text text--darken-1">
              Lengkapi form dibawah ini
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: calc(100vh - 306px); overflow-y: auto">
            <v-form
              ref="form-profile"
              v-model="valid"
              lazy-validation
              @keyup.native.enter="doSave"
            >
              <ImageUpload v-model="form.img_url" class="mb-4" />

              <div class="subtitle-2">
                Nama Lengkap
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <v-text-field
                dense
                solo
                autocomplete="off"
                color="#4ab762"
                type="text"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.name"
                :error-messages="validation.name"
                @keyup="validation.name = ''"
                :rules="[
                  (v) => !!v || `Nama lengkap tidak boleh kosong`,
                  (v) =>
                    (v && v.length <= 100) ||
                    'Nama lengkap maksimal 100 karakter',
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>

              <div class="subtitle-2">
                Username
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <v-text-field
                dense
                solo
                autocomplete="off"
                color="#4ab762"
                type="text"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.username"
                :error-messages="validation.username"
                @keyup="validation.username = ''"
                :rules="[
                  (v) => !!v || `Username tidak boleh kosong`,
                  (v) =>
                    (v && v.length <= 50) || 'Username maksimal 50 karakter',
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>

              <div class="subtitle-2 mt-4">
                Alamat Email <i>(gmail)</i>
                <small style="color: #e74c3c"><i> *wajib diisi</i></small>
              </div>
              <v-text-field
                dense
                solo
                autocomplete="off"
                color="#4ab762"
                type="text"
                placeholder="ex. example@gmail.com"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.email"
                :error-messages="validation.email"
                @keyup="validation.email = ''"
                :rules="[
                  (v) => !!v || `Alamat email tidak boleh kosong`,
                  (v) =>
                    (v && v.length <= 100) ||
                    'Alamat email maksimal 100 karakter',
                  (v) =>
                    /([a-zA-Z0-9]+)([\.{1}])?([a-zA-Z0-9]+)\@gmail([\.])com/g.test(
                      v
                    ) || 'Alamat email harus gmail',
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>

              <div class="subtitle-2 mt-4">
                No. Handphone
                <small style="color: #2ecc71"><i>opsional</i></small>
              </div>
              <v-text-field
                dense
                solo
                autocomplete="off"
                color="#4ab762"
                type="number"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.contact"
                :error-messages="validation.contact"
                @keyup="validation.contact = ''"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>

              <div class="subtitle-2 mt-4">
                Jenis Kelamin
                <small style="color: #2ecc71"><i>opsional</i></small>
              </div>
              <v-radio-group
                v-model="form.gender"
                row
                dense
                hide-details
                class="mt-0"
              >
                <v-radio
                  label="Laki-laki"
                  value="Laki-Laki"
                  color="#4ab762"
                ></v-radio>
                <v-radio
                  label="Perempuan"
                  value="Perempuan"
                  color="#4ab762"
                ></v-radio>
              </v-radio-group>

              <div class="subtitle-2 mt-4">
                Tanggal Lahir
                <small style="color: #2ecc71"><i>opsional</i></small>
              </div>
              <v-dialog
                ref="dialog"
                v-model="dialog.birthdate"
                :return-value.sync="form.birthdate"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    solo
                    autocomplete="off"
                    color="#4ab762"
                    placeholder="Pilih disini"
                    class="rounded-lg mb-3"
                    :disabled="loading"
                    v-model="computedBirthdate"
                    :error-messages="validation.birthdate"
                    @change="validation.birthdate = ''"
                    clearable
                    clear-icon="mdi-backspace"
                    @click:clear="form.birthdate = null"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:message="{ message }">
                      <v-tooltip top max-width="250" color="#e74c3c">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            size="22"
                            class="mr-2 ml-n2"
                            color="#e74c3c"
                            v-bind="attrs"
                            v-on="on"
                            >mdi-alert-circle-outline</v-icon
                          >
                        </template>
                        <span>{{ message }}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  no-title
                  v-model="form.birthdate"
                  class="rounded-lg"
                  color="#4ab762"
                >
                  <div class="d-flex justify-space-between" style="width: 100%">
                    <v-btn
                      rounded
                      depressed
                      class="text-none white--text text--lighten-3 flex-grow-1"
                      color="#4ab762"
                      @click="$refs.dialog.save(form.birthdate)"
                    >
                      <v-icon left>mdi-check-circle-outline</v-icon> Terapkan
                    </v-btn>
                    <v-btn
                      rounded
                      depressed
                      outlined
                      class="text-none ml-2"
                      color="#e74c3c"
                      width="80"
                      @click="dialog.birthdate = false"
                    >
                      Batal
                    </v-btn>
                  </div>
                </v-date-picker>
              </v-dialog>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-space-between px-4">
            <v-btn
              rounded
              depressed
              class="text-none white--text text--lighten-3 flex-grow-1"
              color="#4ab762"
              @click="doSave"
            >
              <v-icon left>mdi-check-circle-outline</v-icon>Simpan Perubahan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { TokenService } from "@/services/service.js";
import moment from "moment";
import "moment/locale/id";
export default {
  name: "Profile",

  components: {
    ImageUpload: () =>
      import(/* webpackChunkName: "ImageUpload" */ "@/components/ImageUpload"),
  },

  data: () => ({
    breadcrumbs: [
      {
        text: "Dashboard",
        disabled: false,
        href: "/",
      },
      {
        text: "Ubah Profil",
        disabled: true,
        href: "/profile",
      },
    ],

    loading: false,

    dialog: {
      birthdate: false,
    },

    valid: true,
    form: {
      img_url: "",
      name: "",
      username: "",
      email: "",
      contact: "",
      gender: "",
      birthdate: "",
    },
    validation: {
      img_url: "",
      name: "",
      username: "",
      email: "",
      contact: "",
      gender: "",
      birthdate: "",
    },

    profile: {},
  }),

  mounted() {
    this.fetchData();
  },

  computed: {
    computedBirthdate() {
      return this.form.birthdate ? moment(this.form.birthdate).format("DD MMMM YYYY") : "";
    },
  },

  methods: {
    setForm() {
      let selected = this.profile;
      let obj = {
        name: selected.name,
        username: selected.username,
        email: selected.email,
        contact: selected.contact,
        gender: selected.gender,
        birthdate: selected.birthdate,
      };

      if (selected.img_url && selected.img_url.length > 0) {
        obj.img_url = selected.img_url;
      }
      Object.assign(this.form, obj);
    },

    validationReset() {
      Object.assign(this.validation, {
        img_url: "",
        name: "",
        username: "",
        email: "",
        contact: "",
        gender: "",
        birthdate: "",
      });
    },

    async doSave() {
      let isValid = this.$refs["form-profile"].validate();

      if (isValid) {
        this.$store.state.overlay.show = true;
        this.$store.state.overlay.text = "Menyimpan perubahan profil...";

        if (this.form.birthdate == null) {
          this.form.birthdate = "";
        }

        let response = await this.$post("admin/profile/update", this.form);

        this.$store.state.overlay.show = false;

        if (response.status == 200) {
          this.$store.state.snackbar = {
            show: true,
            text: "Berhasil merubah profil",
            color: "#2ecc71",
          };

          this.$refs["form-profile"].resetValidation();

          this.validationReset();

          this.fetchData();
        } else if (response.status == 412) {
          this.$store.state.snackbar = {
            show: true,
            text: "Cek kembali form Anda",
            color: "#e74c3c",
          };

          Object.assign(this.validation, response.results.data);
        } else if (response.status == 400) {
          this.$store.state.snackbar = {
            show: true,
            text: response.message,
            color: "#e74c3c",
          };
        }
      }
    },

    async fetchData() {
      this.$store.state.overlay.show = true;
      this.$store.state.overlay.text = "Memuat data profil...";

      const response = await this.$get(`admin/profile`);

      if (response.status === 200) {
        this.profile = response.results;

        let session = this.$store.state.user;
        Object.assign(session, response.results);

        await TokenService.saveUser(session);

        this.$store.commit("setUser", TokenService.getUser());

        this.setForm();
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data profil.",
          color: "#f39c12",
        };
      }

      this.$store.state.overlay.show = false;
    },
  },
};
</script>

<style>
</style>